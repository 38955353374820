import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";
import Table from "../components/UsersTable";
import DatePicker from "../components/DatePicker";
import Pagination from "../components/Pagination";
import Notification from "../components/Notification";
import ShortInput from "../components/ShortInput";
import CardOptions from "../components/CardOptions";
import LoadingAnim from "../components/LoadingAnim";
import userIcon from "../assets/images/userIcon.svg";
import emailIcon from "../assets/images/emailIcon.svg";
import api from "../api/api";
import SmallButton from "../components/SmallButton";
import ShowMatchUsers from "./ShowMatchUsers";
import DropdownSelect from "../components/DropdownSelect";
import { useCallback } from "react";
import VideoPlayer from "./VideoPlayer";
import Checklist from "../components/Checklist";

export default function MatchList({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [editRow, setEditRow] = useState(null);
  const [notification, setNotification] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [players, showPlayers] = useState(false);
  const [RecordData, setRecordData] = useState(null);
  const [recording, setRecording] = useState(false);
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
    name: "",
    email: "",
  });

  const handleBulkDelete = async () => {
    setLoading(true);
    if (
      window.confirm("Are you sure you want to delete this matches?") === true
    ) {
      let checked = tableData?.td.filter((td) => td.checked);
      console.log(checked);
      const res = await api.deleteMatches({
        matchIds: checked.map((item) => item.id),
      });
      if (res) {
        setNotification({
          type: "success",
          text: "Matches deleted.",
        });
      } else {
        setNotification({
          type: "error",
          text: "Please try again..",
        });
      }
      setLoading(false);
      getTableData();
    }
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];
  const [tableData, setTableData] = useState({
    th: [
      "No.",
      "Match ID",
      "Cube Wager",
      "Status",
      "Scheduled Time",
      "Winner",
      "Action",
    ],
    td: [],
  });

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setTableOptions({ ...tableOptions, date: e.target.value });
  };

  const buttonAction = (data) => {
    // console.log(data);
  };

  const handleStatusChange = useCallback(
    (e) => {
      if (!editRow) return;
      editRow.matchStatus = e.target.value;
      setEditRow({ ...editRow });
    },
    [editRow]
  );

  const handleWinnerChange = useCallback(
    (e) => {
      if (!editRow) return;
      editRow.winner = e.target.value;
      setEditRow({ ...editRow });
    },
    [editRow]
  );

  // const handleDateChange = (e) => {
  //   setDate(e.target.value);
  //   setTableOptions({ ...tableOptions, date: e.target.value });
  // };

  const handleFilterEmailChange = (e) => {
    setFilterEmail(e.target.value);
  };
  const handleFilterNameChange = (e) => {
    setFilterName(e.target.value);
  };

  //save
  const handleOnSave = async () => {
    setLoading(true);
    let data = {
      matchStatus: editRow.matchStatus,
      matchId: editRow.id,
      winner: editRow.winner,
    };
    let res = await api.setMatchWinner(data);
    if (res.status === 200) {
      setNotification({
        type: "success",
        text: res.data.message,
      });
      setUpdateTrigger(!updateTrigger);
      setEditRow(null);
      setLoading(false);
      return;
    }
    setNotification({
      type: "error",
      text: res.data.message,
    });
    setEditRow(null);
    setLoading(false);
    setUpdateTrigger(!updateTrigger);
  };
  //delete
  const handleOnDelete = async () => {
    if (window.confirm("Are you sure you want to delete this user?") === true) {
      // setLoading(true);
      // let res = await users.deleteUser({ id: editRow.id });
      // if (res.message.includes("Deleted")) {
      //   setNotification({
      //     type: "success",
      //     text: "User deleted successfully",
      //   });
      //   setEditRow(null);
      //   setLoading(false);
      //   return;
      // }
      // setNotification({
      //   type: "error",
      //   text: "Something went wrong",
      // });
      // setEditRow(null);
      // setLoading(false);
    }
  };

  const handleOnPlayers = async () => {
    showPlayers(!players);
  };

  const getTableData = async () => {
    setLoading(true);
    const data = await api.getMatchData(tableOptions);
    setCountTotal(data.totalCount);
    tableData.td = [];
    data.matches.map((match, index) => {
      tableData.td.push({
        id: match._id,
        checked: false,
        matchId: match?.matchId,
        cubeWager: match?.cubeWager,
        matchStatus: match?.matchStatus,
        scheduledTime: match?.scheduledTime
          ? new Date(match?.scheduledTime).toLocaleString()
          : new Date(match?.startTime).toLocaleString(),
        winner:
          match?.winner === 0
            ? "Disputed"
            : match?.winner === 1
            ? "Team One"
            : match?.winner === 2
            ? "Team Two"
            : "N/A",
        button: {
          action: buttonAction,
          text: "Update",
          disabled: match?.winner !== 0,
        },
        cubeWager: match?.cubeWager,
        rounds: match?.rounds,
        gameType: match?.gameType,
        game: match?.game?.name,
        matchType: match?.matchType,
        team1: match?.team1,
        team2: match?.team2,
      });
    });
    setLoading(false);
    setTableData({ ...tableData });
  };

  useEffect(() => {
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    setLoading(true);
    let timer = setTimeout(() => {
      setTableOptions({
        ...tableOptions,
        name: filterName,
        email: filterEmail,
      });
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
      setLoading(false);
    };
  }, [filterName, filterEmail]);

  const handleLive = async (stream) => {
    switch (stream.type) {
      case "IN_APP":
        const data = await api.getSDKMatchStreamRecording(stream.id);
        if (!data?.data?.message) {
          setRecording(true);
          setRecordData(data);
        } else {
          setNotification({
            type: "error",
            text: "This user is not recording the live",
          });
        }
        break;
      case "TWITCH":
        const res = await api.getTwitchMatchStreamRecording(stream.id);
        if (res) {
          setRecording(true);
          setRecordData(res);
        } else {
          setNotification({
            type: "error",
            text: "This user is not recording the live",
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Match List</span>
          <div className={styles.cardOptionsWrapper}>
            <CardOptions options={options} />
          </div>
        </div>
        <div className={styles.cardDropDowns}>
          <DatePicker action={handleDateChange} />
          <ShortInput
            defaultValue={editRow?.name}
            placeholder="Name"
            icon={userIcon}
            action={handleFilterNameChange}
          />
          <ShortInput
            defaultValue={editRow?.email}
            placeholder="Email"
            icon={emailIcon}
            action={handleFilterEmailChange}
          />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            handleShow={handleOnPlayers}
            Filters={
              <Filters
                handleStatusChange={handleStatusChange}
                handleWinnerChange={handleWinnerChange}
                editRow={editRow}
              />
            }
            handleOnSave={handleOnSave}
          />
        </div>
      </div>

      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
      {players && (
        <ShowMatchUsers
          updateTrigger={players}
          setUpdateTrigger={() => showPlayers(false)}
          data={editRow}
          visible={players}
          handleLive={handleLive}
        />
      )}
      {recording && (
        <VideoPlayer
          setUpdateTrigger={() => setRecording(false)}
          data={RecordData}
        />
      )}
    </>
  );
}
export function Filters({ editRow, handleStatusChange, handleWinnerChange }) {

  return (
    <>
      <ShortInput
        defaultValue={editRow?.matchStatus}
        placeholder="Match Status"
        action={handleStatusChange}
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.cubeWager}
        placeholder="Match Cubes"
        action={handleStatusChange}
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.matchType}
        placeholder="Match Type"
        action={handleStatusChange}
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.rounds}
        placeholder="Match Rounds"
        action={handleStatusChange}
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.game}
        placeholder="Game"
        action={handleStatusChange}
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.gameType}
        placeholder="Game Type"
        action={handleStatusChange}
        disabled={true}
      />
      <Checklist
        items={editRow?.team1}
        placeholder="Team_01"
        checkedItems={[]}
        setCheckedItems={""}
      />
      <Checklist
        items={editRow?.team2}
        placeholder="Team_02"
        checkedItems={[]}
        setCheckedItems={""}
      />
      <DropdownSelect
        disabled={editRow?.winner !== "Disputed"}
        defaultValue={{
          text: editRow?.winner,
          value:
            editRow?.winner === "Disputed"
              ? 0
              : editRow?.winner === "Team One"
              ? 1
              : 2,
        }}
        placeholder="Winner"
        action={handleWinnerChange}
        options={[
          {
            text: "Disputed",
            value: 0,
          },
          {
            text: "Team One",
            value: 1,
          },
          {
            text: "Team Two",
            value: 2,
          },
        ]}
      />
    </>
  );
}
