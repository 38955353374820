import React, { useEffect, useState } from "react";
import styles from "../styles/cards/showMatchUsers.scss";
import LoadingAnim from "../components/LoadingAnim";
import Notification from "../components/Notification";

export default function VideoPlayer({ updateTrigger, setUpdateTrigger, data }) {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close-button" onClick={setUpdateTrigger}>
          Close
        </span>
        <span className="modal-header">Watch Recording</span>
        <video
          className="video-content"
          src={data}
          controls={true}
          autoPlay
          onError={(e) => console.error("Video error: ", e)}
        />
        <LoadingAnim loading={loading} />
        <Notification data={notification} />
      </div>
    </div>
  );
}
