import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";

import Table from "../components/UsersTable";
import DatePicker from "../components/DatePicker";
import Pagination from "../components/Pagination";
import Notification from "../components/Notification";
import ShortInput from "../components/ShortInput";
import CardOptions from "../components/CardOptions";
import LoadingAnim from "../components/LoadingAnim";

import userIcon from "../assets/images/userIcon.svg";
import emailIcon from "../assets/images/emailIcon.svg";
import api from "../api/api";
import ShowMatchUsers from "./ShowMatchUsers";

export default function TournamentList({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [editRow, setEditRow] = useState(null);
  const [notification, setNotification] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
    name: "",
    email: "",
  });
  const [players, showPlayers] = useState(false);

  const handleBulkDelete = async () => {
    setLoading(true);
    if (
      window.confirm("Are you sure you want to delete this tournaments?") ===
      true
    ) {
      let checked = tableData?.td.filter((td) => td.checked);
      console.log(checked);
      const res = await api.deleteTournaments({
        tournamentIds: checked.map((item) => item.id),
      });
      if (res) {
        setNotification({
          type: "success",
          text: "Tournaments deleted.",
        });
      } else {
        setNotification({
          type: "error",
          text: "Please try again..",
        });
      }
      setLoading(false);
    }
    getTableData();
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];
  const [tableData, setTableData] = useState({
    th: [
      "No.",
      "Tournament ID",
      "Cube Wager",
      "Status",
      "Current Stage",
      "Date",
      "Edit",
    ],
    td: [],
  });

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setTableOptions({ ...tableOptions, date: e.target.value });
  };

  const buttonAction = (data) => {
    console.log(data);
  };

  const handStatusChange = (e) => {
    if (!editRow) return;
    editRow.tournamentStatus = e.target.value;
    setEditRow({ ...editRow });
  };
  const handleStageChange = (e) => {
    if (!editRow) return;
    editRow.currentStage = e.target.value;
    setEditRow({ ...editRow });
  };

  // const handleDateChange = (e) => {
  //   setDate(e.target.value);
  //   setTableOptions({ ...tableOptions, date: e.target.value });
  // };

  const handleFilterEmailChange = (e) => {
    setFilterEmail(e.target.value);
  };
  const handleFilterNameChange = (e) => {
    setFilterName(e.target.value);
  };

  //save
  const handleOnSave = async () => {
    setLoading(true);
    console.log(editRow, "save");
    let data = {
      tournamentStatus: editRow.tournamentStatus,
      currentStage: editRow.currentStage,
      id: editRow.id,
    };
    let res = await api.updateTournament(data);
    if (res.message) {
      setNotification({
        type: "success",
        text: "User updated successfully",
      });
      setUpdateTrigger(!updateTrigger);
      setEditRow(null);
      setLoading(false);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
    setUpdateTrigger(!updateTrigger);
  };

  const handleOnPlayers = async () => {
    showPlayers(!players);
  };

  useEffect(() => {
    console.log(editRow);
  }, [editRow]);

  const getTableData = async () => {
    setLoading(true);
    const data = await api.getTournamentData(tableOptions);
    setCountTotal(data.totalCount);

    tableData.td = [];
    data.tournaments.map((tournament, index) => {
      tableData.td.push({
        id: tournament._id,
        checked: false,
        tournamentId: tournament.tournamentId,
        cubeWager: tournament.cubeWager,
        tournamentStatus: tournament.tournamentStatus,
        currentStage: tournament.currentStage,
        date: new Date(tournament.tournamentDate).toLocaleString(),
        button: { action: buttonAction, text: "Info" },
      });
    });
    setLoading(false);
    console.log(tableData.td);
    setTableData({ ...tableData });
  };

  useEffect(() => {
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    setLoading(true);
    let timer = setTimeout(() => {
      setTableOptions({
        ...tableOptions,
        name: filterName,
        email: filterEmail,
      });
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
      setLoading(false);
    };
  }, [filterName, filterEmail]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Tournament List</span>
          <div className={styles.cardOptionsWrapper}>
            <CardOptions options={options} />
          </div>
        </div>
        <div className={styles.cardDropDowns}>
          <DatePicker action={handleDateChange} />
          <ShortInput
            defaultValue={editRow?.name}
            placeholder="Name"
            icon={userIcon}
            action={handleFilterNameChange}
          />
          <ShortInput
            defaultValue={editRow?.email}
            placeholder="Email"
            icon={emailIcon}
            action={handleFilterEmailChange}
          />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            handleShow={handleOnPlayers}
            notEditable={true}
            Filters={
              <Filters
                handleNameChange={handStatusChange}
                handleEmailChange={handleStageChange}
                editRow={editRow}
              />
            }
            handleOnSave={handleOnSave}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
      {players && (
        <ShowMatchUsers
          updateTrigger={players}
          setUpdateTrigger={() => showPlayers(false)}
          data={editRow}
          type="TOURNAMENT"
        />
      )}
    </>
  );
}
export function Filters({ editRow, handleNameChange, handleEmailChange }) {
  return (
    <>
      <ShortInput
        defaultValue={editRow?.tournamentStatus}
        placeholder="Tournament Status"
        icon={userIcon}
        action={handleNameChange}
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.currentStage}
        placeholder="Current Stage"
        icon={emailIcon}
        action={handleEmailChange}
        disabled={true}
      />
    </>
  );
}
