import React, { useEffect, useState } from "react";
import styles from "../styles/cards/showMatchUsers.scss";
import api from "../api/api";
import NewTable from "./Table";
import LoadingAnim from "../components/LoadingAnim";
import Notification from "../components/Notification";
import ShortInput from "../components/ShortInput";
import DropdownSelect from "../components/DropdownSelect";
import SmallButton from "../components/SmallButton";

export default function ShowMatchUsers({
  updateTrigger,
  setUpdateTrigger,
  data,
  type,
  visible,
  handleLive,
}) {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [tableData, setTableData] = useState({
    th: [
      "Player",
      "Team",
      "Console",
      "Provider_Type",
      "Provider_ID",
      "Live",
      "Refund",
    ],
    td: [],
  });
  const [playerList, setPlayerList] = useState([]);
  const [winnerData, setWinnerData] = useState(null);
  const [details, setDetails] = useState();

  const selectedProviders = (provider) => {
    switch (provider) {
      case "EPIC":
        return "epicId";
      case "ACTIVISION":
        return "activisionId";
      case "ORIGIN":
        return "originId";
      case "STEAM":
        return "steamId";
      case "PSN":
        return "psnGamerTag";
      case "XBL":
        return "xboxId";
      case "NINTENDO":
        return "nintendoId";
      case "BATTLE_NET":
        return "battleNetId";
      case "EA":
        return "eaId";
      case "STREET_FIGHTER":
        return "streetFighterId";
      case "MLB":
        return "mlbId";
      case "N/A":
        return "N/A";
      default:
        break;
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      let resData;
      let mergedArray = [];
      let team1 = [];
      let playerConsoles = [];
      if (type === "TOURNAMENT") {
        resData = await api.getTournamentUsersData(data.id);
        setDetails(resData);
        setWinnerData({ value: resData.winner });
        mergedArray = resData.players.map((player) => player.user);
      } else {
        resData = await api.getMatchUsersData(data.id);
        const res = resData?.matchDetails;
        setDetails(res);
        setWinnerData({ value: res.winner });
        mergedArray = [...res.team1, ...res.team2];
        team1 = res?.team1;
        playerConsoles = res.playerConsoles;
      }
      setPlayerList(mergedArray.map((user) => user._id));
      const newTableData = mergedArray.map((user) => ({
        Player: user?.username || "N/A",
        Team: team1?.find((player) => player?._id === user?._id)
          ? "Team 01"
          : team1
          ? "Team 02"
          : details.teams.find((player) => player?.players.includes(user._id))
              .teamNo === 1
          ? "Team 01"
          : "Team 02",
        Console: playerConsoles.find((player) => player.user === user._id)
          ? playerConsoles.find((player) => player.user === user._id)?.console
          : "N/A",
        Provider_Type: playerConsoles.find((player) => player.user === user._id)
          ? playerConsoles.find((player) => player.user === user._id)?.provider
          : "N/A",
        Provider_ID:
          selectedProviders(
            playerConsoles.find((player) => player.user === user._id)
              ? playerConsoles.find((player) => player.user === user._id)
                  ?.provider
              : "N/A"
          ) !== "N/A"
            ? user[
                selectedProviders(
                  playerConsoles.find((player) => player.user === user._id)
                    ? playerConsoles.find((player) => player.user === user._id)
                        ?.provider
                    : "N/A"
                )
              ]
            : "N/A",
        Live: `Streamer/${
          resData?.matchStreams?.find((stream) => stream?.userId === user._id)
            ? resData?.matchStreams?.find(
                (stream) => stream?.userId === user._id
              )?._id
            : "No Live"
        }&${
          resData?.matchStreams?.find((stream) => stream?.userId === user._id)
            ? resData?.matchStreams?.find(
                (stream) => stream?.userId === user._id
              )?.streamType
            : ""
        }`,
        button: "Refund",
        userID: user._id,
      }));
      setTableData({
        th: [...tableData.th],
        td: newTableData,
      });

      setLoading(false);
    } catch (error) {
      console.error("Error fetching match users data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [data, visible]);

  const handleRefund = async (userData) => {
    setLoading(true);

    try {
      const res = await api.refundAll({
        eventId: data?.id,
        userId: userData.userID,
      });
      console.log(res);
      if (!res.status) {
        setNotification({
          type: "success",
          text: res.message,
        });
      } else {
        setNotification({
          type: "error",
          text: res.message,
        });
      }
    } catch (error) {
      console.log(`Fail to refund - ${userData.userID}`);
    }

    setLoading(false);
  };

  const handleWinnerChange = (e) => {
    setWinnerData({ value: e.target.value });
  };

  const renderWinner = (data) => {
    if (data === 2) return "Team Two";
    if (data === 1) return "Team One";
    if (data === 0) return "Disputed";
    return "";
  };
  const handleClick = async () => {
    setLoading(true);
    let data = {
      matchStatus: details.matchStatus,
      matchId: details._id,
      winner: winnerData.value,
    };
    let res = await api.setMatchWinner(data);
    if (res.status === 200) {
      setNotification({
        type: "success",
        text: res.data.message,
      });
      fetchData();
      setLoading(false);
      return;
    }
    setNotification({
      type: "error",
      text: res.data.message,
    });
    setLoading(false);
    fetchData();
  };
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close-button" onClick={setUpdateTrigger}>
          Close
        </span>
        <span className="modal-header">
          Players of -{" "}
          {type === "TOURNAMENT" ? data.tournamentId : data.matchId}{" "}
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            margin: 4,
          }}
        >
          <ShortInput
            defaultValue={details?.matchStatus || details?.tournamentStatus}
            placeholder="Match Status"
            disabled={true}
          />
          <ShortInput
            defaultValue={details?.cubeWager}
            placeholder="Match Cubes"
            disabled={true}
          />
          <ShortInput
            defaultValue={details?.matchType}
            placeholder="Match Type"
            disabled={true}
          />
          <ShortInput
            defaultValue={details?.rounds}
            placeholder="Match Rounds"
            disabled={true}
          />
          <ShortInput
            defaultValue={details?.game.name}
            placeholder="Game"
            disabled={true}
          />
          <ShortInput
            defaultValue={details?.gameType}
            placeholder="Game Type"
            disabled={true}
          />
          {details?.winner !== 0 && (
            <ShortInput
              defaultValue={renderWinner(details?.winner)}
              placeholder="Winner"
              disabled={true}
            />
          )}
          {details?.winner === 0 && (
            <>
              <DropdownSelect
                disabled={details?.winner !== 0}
                defaultValue={{
                  text: renderWinner(details?.winner),
                  value: details?.winner,
                }}
                placeholder="Winner"
                action={handleWinnerChange}
                options={[
                  {
                    text: "Disputed",
                    value: 0,
                  },
                  {
                    text: "Team One",
                    value: 1,
                  },
                  {
                    text: "Team Two",
                    value: 2,
                  },
                ]}
              />
              <div
                style={{
                  margin: 6,
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <SmallButton
                  type="primary"
                  text={"Submit"}
                  onClick={() => handleClick()}
                />
              </div>
            </>
          )}
        </div>
        <NewTable
          tableData={tableData}
          setTableData={setTableData}
          handleClick={handleRefund}
          handleLive={handleLive}
        />
        <LoadingAnim loading={loading} />
        <Notification data={notification} />
      </div>
    </div>
  );
}
