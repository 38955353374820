import React, { useState, useEffect, useCallback } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";
import Table from "../components/UsersTable";
import Pagination from "../components/Pagination";
import Notification from "../components/Notification";
import ShortInput from "../components/ShortInput";
import LoadingAnim from "../components/LoadingAnim";
import DropdownSelect from "../components/DropdownSelect";

import userIcon from "../assets/images/userIcon.svg";
import api from "../api/api";

export default function KycApproval({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [editRow, setEditRow] = useState(null);
  const [notification, setNotification] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
    name: "",
    email: "",
  });

  const [tableData, setTableData] = useState({
    th: ["No.", "UserID", "UserName", "Date", "Email", "Action"],
    td: [],
  });

  const buttonAction = (data) => {
    console.log(data);
  };

  //update state
  const HandleOnUpdate = async () => {
    setLoading(true);
    let res = await api.approveWallet({
      walletId: editRow.id,
      isApproved: editRow.approved === "true" ? true : false,
    });
    setLoading(false);
    if (res === "error") {
      setLoading(false);
      setNotification({
        type: "error",
        text: res.data.message,
      });
      setEditRow(null);
      setLoading(false);
      setUpdateTrigger(!updateTrigger);
    } else {
      setNotification({
        type: "success",
        text: "Kyc Approved.",
      });
    }
    setLoading(false);
  };

  const getTableData = async () => {
    setLoading(true);
    const data = await api.getKycData(tableOptions.page);
    setCountTotal(data.totalPages * 10);
    setLoading(false);

    const sortedTd = data?.wallets
      .map((item) => ({
        id: item?._id,
        checked: false,
        UserID: item?.owner?._id,
        UserName: item?.owner?.username,
        Date: new Date(item?.updatedAt).toLocaleDateString(),
        Email: item?.owner?.email,
        button: {
          action: buttonAction,
          text: "Update",
          type: "primary",
        },
        idImage: item?.images,
      }))
      .sort((a, b) => new Date(b.date) - new Date(a.date));

    setTableData({ ...tableData, td: sortedTd });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(false);
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    setLoading(true);
    let timer = setTimeout(() => {
      setTableOptions({
        ...tableOptions,
        name: filterName,
        email: filterEmail,
      });
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
      setLoading(false);
    };
  }, [filterName, filterEmail]);

  const handleStatusChange = useCallback(
    (e) => {
      if (!editRow) return;
      editRow.approved = e.target.value;
      setEditRow({ ...editRow });
    },
    [editRow]
  );

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>KYC Requests</span>
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={
              <Filters
                editRow={editRow}
                handleStatusChange={handleStatusChange}
              />
            }
            handleOnSave={HandleOnUpdate}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
export function Filters({ editRow, handleStatusChange }) {
  return (
    <>
      <ShortInput
        defaultValue={editRow?.UserID}
        placeholder="ID"
        icon={userIcon}
        disabled={editRow?.button?.type === "view"}
      />
      <ShortInput
        defaultValue={editRow?.UserName}
        placeholder="Name"
        icon={userIcon}
        disabled={editRow?.button?.type === "view"}
      />
      <ShortInput
        placeholder="Kyc Documents"
        url={editRow.idImage[0]}
        urlText="Click here to Show KYC Images"
        disabled
      />
      <ShortInput
        placeholder="Kyc Documents"
        url={editRow.idImage[1]}
        urlText="Click here to Show KYC Images"
        disabled
      />
      <DropdownSelect
        defaultValue={{
          text: editRow?.approved ? "Approve" : "Select Status",
          value: editRow?.approved ? true : 0,
        }}
        placeholder="Status"
        action={handleStatusChange}
        options={[
          {
            text: "Approve",
            value: true,
          },
          {
            text: "Decline",
            value: false,
          },
          {
            text: "Select Status",
            value: 0,
          },
        ]}
      />
    </>
  );
}
