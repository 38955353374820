import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";
import Table from "../components/UsersTable";
import DatePicker from "../components/DatePicker";
import Pagination from "../components/Pagination";
import Notification from "../components/Notification";
import ShortInput from "../components/ShortInput";
import CardOptions from "../components/CardOptions";
import LoadingAnim from "../components/LoadingAnim";
import userIcon from "../assets/images/userIcon.svg";
import emailIcon from "../assets/images/emailIcon.svg";
import api from "../api/api";
import TextInput from "../components/TextInput";
import TextAreaInput from "../components/TextAreaInput";

export default function ComplaintList({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [editRow, setEditRow] = useState(null);
  const [notification, setNotification] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
    name: "",
    email: "",
  });

  const handleBulkDelete = async () => {
    if (!window.confirm("Are you sure you want to delete these users?")) return;
    let checked = tableData?.td.filter((td) => td.checked);
    // setLoading(true);
    // let res = await users.deleteBulk(checked);
    // if (res.message.includes("Deleted")) {
    //   setNotification({
    //     type: "success",
    //     text: "Users deleted successfully",
    //   });
    //   setEditRow(null);
    //   setLoading(false);
    //   setUpdateTrigger(!updateTrigger);
    //   return;
    // }
    // setNotification({
    //   type: "error",
    //   text: "Something went wrong",
    // });
    // setEditRow(null);
    // setLoading(false);
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];
  const [tableData, setTableData] = useState({
    th: ["No.", "User ID", "Email", "Topic", "Description", "Action"],
    td: [],
  });

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setTableOptions({ ...tableOptions, date: e.target.value });
  };

  const buttonAction = (data) => {
    console.log(data);
  };

  const handleResponseChange = (e) => {
    if (!editRow) return;
    editRow.reply = e.target.value;
    setEditRow({ ...editRow });
  };

  // const handleDateChange = (e) => {
  //   setDate(e.target.value);
  //   setTableOptions({ ...tableOptions, date: e.target.value });
  // };

  const handleFilterEmailChange = (e) => {
    setFilterEmail(e.target.value);
  };
  const handleFilterNameChange = (e) => {
    setFilterName(e.target.value);
  };

  //save
  const handleOnSave = async () => {
    //TODO

    setLoading(true);
    let res = await api.handleComplaint(editRow);
    if (res === "error") {
      setNotification({
        type: "error",
        text: "Something went wrong.Please try again later.",
      });
      setEditRow(null);
      setLoading(false);
      setUpdateTrigger(!updateTrigger);
    } else {
      setNotification({
        type: "success",
        text: "User updated successfully",
      });
      setUpdateTrigger(!updateTrigger);
      setEditRow(null);
      setLoading(false);
    }
  };
  //delete
  const handleOnDelete = async () => {
    if (window.confirm("Are you sure you want to delete this user?") === true) {
      // setLoading(true);
      // let res = await users.deleteUser({ id: editRow.id });
      // if (res.message.includes("Deleted")) {
      //   setNotification({
      //     type: "success",
      //     text: "User deleted successfully",
      //   });
      //   setEditRow(null);
      //   setLoading(false);
      //   return;
      // }
      // setNotification({
      //   type: "error",
      //   text: "Something went wrong",
      // });
      // setEditRow(null);
      // setLoading(false);
    }
  };

  useEffect(() => {
    const getTableData = async () => {
      setLoading(true);
      const data = await api.getComplaintsData(tableOptions);
      setCountTotal(data.totalCount);
      tableData.td = [];
      data.complaints.map((complaint, index) => {
        tableData.td.push({
          id: complaint._id,
          checked: false,
          userId: complaint?.user?._id,
          email: complaint?.user?.email,
          topic: complaint?.topic,
          description: complaint?.description,
          button: { action: buttonAction, text: "Action" },
          name: complaint?.user?.username,
        });
      });
      setLoading(false);
      console.log(tableData.td);
      setTableData({ ...tableData });
    };
    setLoading(false);
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    setLoading(true);
    let timer = setTimeout(() => {
      setTableOptions({
        ...tableOptions,
        name: filterName,
        email: filterEmail,
      });
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
      setLoading(false);
    };
  }, [filterName, filterEmail]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Complaint List</span>
          <div className={styles.cardOptionsWrapper}>
            <CardOptions options={options} />
          </div>
        </div>
        <div className={styles.cardDropDowns}>
          <DatePicker action={handleDateChange} />
          <ShortInput
            defaultValue={editRow?.name}
            placeholder="Name"
            icon={userIcon}
            action={handleFilterNameChange}
          />
          <ShortInput
            defaultValue={editRow?.email}
            placeholder="Email"
            icon={emailIcon}
            action={handleFilterEmailChange}
          />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={
              <Filters
                handleResponseChange={handleResponseChange}
                editRow={editRow}
              />
            }
            handleOnSave={handleOnSave}
            handleOnDelete={handleOnDelete}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
export function Filters({ editRow, handleResponseChange }) {
  return (
    <>
      <ShortInput
        defaultValue={editRow?.name}
        placeholder="Name"
        icon={userIcon}
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.email}
        placeholder="Email"
        icon={emailIcon}
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.topic}
        placeholder="Topic"
        disabled={true}
      />
      <TextInput
        defaultValue={editRow?.description}
        disabled={true}
        label={"Description: "}
        customStyles={{ height: "58px", alignItems: "flex-start" }}
      />
      <TextAreaInput
        disabled={false}
        onChange={handleResponseChange}
        placeholder={"Write a reply"}
        label={"Reply: "}
        customStyles={{
          height: "auto",
          alignItems: "flex-start",
          marginTop: "10px",
        }}
        inputStyles={{
          minHeight: "80px",
        }}
        rows={8}
      />
    </>
  );
}
