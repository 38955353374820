import React from "react";
import styles from "../styles/components/textInput.module.scss";

export default function TextAreaInput({
  onChange,
  defaultValue,
  placeholder,
  customStyles,
  inputStyles,
  disabled,
  label,
  rows,
}) {
  return (
    <div className={styles.textInputContainer} style={customStyles || {}}>
      {label && <span className={styles.label}>{label}</span>}
      <textarea
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        rows={rows}
        style={inputStyles}
      />
    </div>
  );
}
